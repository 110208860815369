<template>
  <div
    :class="[
      'password-label d-flex align-items-center',
      { 'invisible-label': !showPassword, 'visible-label': showPassword },
    ]"
  >
    <span v-if="showPassword">
      <CopyButtonLabel :value="value" />
    </span>
    <span v-else> ********** </span>

    <i
      class="far fa-eye cursor-pointer customVisibleIconMt"
      v-if="!showPassword"
      @click="changePasswordVisibility()"
    ></i>
    <i
      class="far fa-eye-slash pb-1 cursor-pointer"
      v-if="showPassword"
      @click="changePasswordVisibility()"
    ></i>
  </div>
</template>

<script>
export default {
  name: 'PasswordLabel',
  props: {
    value: {
      type: String,
      default: '*********',
    },
  },
  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    changePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
.password-label {
  &.invisible-label {
    margin-top: 4px;
  }
  &.visible-label {
    margin-bottom: 4px;
  }
  & i {
    font-size: 12px;
    line-height: 18px;
    margin-left: 10px;
  }
  .customVisibleIconMt {
    margin-top: -4px;
  }
}
</style>
